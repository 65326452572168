<style scope lang="less">
.Teacher-Cloud {
  background: #fff;
  box-shadow: @shadow;

  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }

  .dropdown_btn {
    margin-right: 10px;
  }

  .content {
    padding: 20px;

    .form_div {
      display: flex;
      align-items: center;

      .el-input {
        width: 230px;
      }

      .view_type {
        margin-left: 20px;

        i {
          margin-right: 10px;
          cursor: pointer;
          font-size: 20px;
        }

        .active {
          color: @mainColor;
        }
      }
    }

    .title {
      margin-top: 20px;
      height: 32px;
      line-height: 32px;
    }

    .rootBtn {
      margin: 5px 0;
    }
  }
}
</style>
<template>
  <div class="Teacher-Cloud">
    <div class="stu-module-header">
      <div class="stu-module-title">我的网盘</div>
      <div v-if="queryRoot">
        <el-dropdown
          split-button
          type="primary"
          @click="handleSort(sortBtnName)"
          @command="commandSort"
          class="dropdown_btn"
        >
          {{ sortBtnName }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0,文件名称">文件名称</el-dropdown-item>
            <el-dropdown-item command="1,文件类型">文件类型</el-dropdown-item>
            <el-dropdown-item command="2,文件大小">文件大小</el-dropdown-item>
            <el-dropdown-item command="3,创建时间">创建时间</el-dropdown-item>
            <el-dropdown-item command="3,最近上传">最近上传</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          icon="el-icon-circle-plus-outline"
          type="primary"
          @click="handleNewFolder"
          v-if="folderRoot"
        >
          新建文件夹
        </el-button>
        <el-button
          icon="el-icon-upload2"
          type="primary"
          @click="handleUploadFile"
          v-if="resourceRoot"
        >
          上传资源
        </el-button>
        <el-button
          icon="el-icon-delete"
          type="primary"
          v-if="delRoot"
          @click="handleDelete"
        >
          删除
        </el-button>
        <el-button
          icon="el-icon-rank"
          type="primary"
          v-if="removeRoot"
          @click="handleMove"
        >
          移动
        </el-button>
      </div>
    </div>
    <div class="content" v-if="queryRoot">
      <div class="form_div">
        <el-input v-model="form.info" size="medium" placeholder="搜索文件">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="tagTypeQuery(sortBtnName)"
          ></el-button>
        </el-input>
        <div class="view_type">
          <i
            @click="active = true"
            class="el-icon-s-operation"
            :class="active ? 'active' : ''"
          ></i>
          <i
            @click="active = false"
            class="el-icon-s-grid"
            :class="!active ? 'active' : ''"
          ></i>
        </div>
      </div>
      <div v-loading="loading" style="min-height: 100px">
        <!-- 列表模式 -->
        <ListModel
          ref="listModelRef"
          v-if="active"
          :data="modelData"
          :columns="columns"
          :total="total"
          :page="page"
          @pfileId="geiPFileId"
          :pageSize="pageSize"
          @pageInit="pageInit"
          @handleSelection="handleSelection"
        ></ListModel>
        <!-- 视图模式 -->
        <view-model
          v-else
          ref="viewModelRef"
          :data="modelData"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          @pfileId="geiPFileId"
          @pageInit="pageInit"
          @handleSelection="handleSelection"
        ></view-model>
      </div>
    </div>
    <no-root v-else />
    <!-- 新建文件夹 -->
    <NewFolder ref="newFolderRef" :PFileId="PFileId" @initQuery="initQuery" />
    <!-- 上传资源 -->
    <UploadFile ref="uploadFileRef" :PFileId="PFileId" @initQuery="initQuery" />
    <!-- 移动 -->
    <Move
      ref="moveRef"
      :ids="selectionData.length ? selectionData.map((m) => m.id).join() : null"
      :data="folderData"
      @initQuery="initQuery"
    />
  </div>
</template>

<script>
import NewFolder from "./module/NewFolder.vue";
import UploadFile from "./module/UploadFile.vue";
import Move from "./module/Move.vue";
import ListModel from "./model/ListModel";
import ViewModel from "./model/ViewModel";

import {
  EduNetdiskList,
  EduNetdiskMove,
  EduNetdiskDelete,
  EduNetdiskSort,
  EduNetdiskListDG,
} from "@/libs/api/teacher/cloud";
export default {
  name: "teacher-cloud",
  components: { NewFolder, UploadFile, Move, ListModel, ViewModel },
  data() {
    return {
      loading: false,
      sortBtnName: "默认排序",
      searchText: "",
      active: true,
      form: {
        info: "", //文件名
        teacherId: "", //教师ID
      },
      page: 1,
      pageSize: 12,
      total: 0,
      modelData: [],
      folderData: [], // 所有文件夹的集合
      columns: [
        { label: "文件名称", prop: "fileName", tooltip: true },
        { label: "创建时间", prop: "createTime", width: 140, tooltip: true },
        // {label: "类型", prop: "fileType"},
        // {label: "文件大小", prop: "fileSize"},
      ],
      selectionData: [], // 选中数据
      PFileId: 0,
    };
  },
  watch: {
    active(n) {
      this.selectionData = [];
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("eduTeacherCloud:list");
    },
    folderRoot() {
      return this.$sysPerKey("eduTeacherCloud:insert");
    },
    resourceRoot() {
      return this.$sysPerKey("eduTeacherCloud:insertResource");
    },
    delRoot() {
      return this.$sysPerKey("eduTeacherCloud:deleted");
    },
    removeRoot() {
      return this.$sysPerKey("eduTeacherCloud:remove");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.user && this.query();
    },
  },
  mounted() {
    this.queryRoot && this.user && this.query();
  },
  methods: {
    query() {
      let formData = new FormData();
      formData.append("page", this.page);
      formData.append("pageSize", this.PFileId ? '' : this.pageSize);
      formData.append("info", this.form.info);
      // formData.append("teacherId", this.user.id);
      formData.append("PFileId", this.PFileId || 0);
      this.loading = true;
      EduNetdiskList(formData)
        .then((res) => {
          this.loading = false;
          this.queryChild(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    geiPFileId(id) {
      this.PFileId = id;
    },
    pageInit(page) {
      this.page = page;
      this.tagTypeQuery(this.sortBtnName);
    },
    queryChild(res) {
      if (this.PFileId && this.PFileId !== 0) {
        if (this.$refs["listModelRef"]) {
          this.$refs["listModelRef"].children = res.data.list;
          // this.$refs["listModelRef"].childData = [];
        }
        if (this.$refs["viewModelRef"]) {
          this.$refs["viewModelRef"].children = res.data.list;
          // this.$refs["viewModelRef"].childData = [];
        }
      } else {
        this.modelData = res.data.list;
        this.total = res.data.total;
      }
    },
    tagTypeQuery(name) {
      if (!this.PFileId || this.PFileId === 0) {
        if (this.$refs["listModelRef"]) {
          this.$refs["listModelRef"].children = null;
          this.$refs["listModelRef"].childData = [];
        }
        if (this.$refs["viewModelRef"]) {
          this.$refs["viewModelRef"].children = null;
          this.$refs["viewModelRef"].childData = [];
        }
      }
      if (name === "默认排序") this.query();
      else if (name === "文件名称") this.SortQuery("0");
      else if (name === "文件类型") this.SortQuery("1");
      else if (name === "文件大小") this.SortQuery("2");
      else if (name === "创建时间") this.SortQuery("3");
      else if (name === "最近时间") this.SortQuery("3");
    },
    handleSelection(val) {
      this.selectionData = val;
    },
    handleSort(name) {
      this.tagTypeQuery(name);
    },
    commandSort(item) {
      // 0文件名 1文件类型 2文件大小 3创建时间、最近上传
      const tag = item.split(",")[0];
      const name = item.split(",")[1];
      this.sortBtnName = name;
      this.SortQuery(tag);
    },
    SortQuery(tag) {
      let data = new FormData();
      data.append("sort", 1);
      data.append("identification", tag);
      data.append("info", this.form.info);
      data.append("page", this.page);
      data.append("pageSize", this.PFileId ? '' : this.pageSize);
      data.append("PFileId", this.PFileId || 0);
      EduNetdiskSort(data).then((res) => {
        this.queryChild(res);
      });
    },
    handleNewFolder() {
      this.$refs["newFolderRef"].dialog = true;
    },
    handleUploadFile() {
      this.$refs["uploadFileRef"].dialog = true;
    },
    handleDelete() {
      if (!this.selectionData.length) {
        return this.$message.error("请选择数据");
      }
      this.$confirm("确定要删除吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = new FormData();
          data.append("ids", this.selectionData.map((m) => m.id).join());
          EduNetdiskDelete(data).then((res) => {
            this.tagTypeQuery(this.sortBtnName);
          });
        })
        .catch(() => {});
    },
    handleMove() {
      if (!this.selectionData.length) {
        return this.$message.error("请选择数据");
      }
      this.$refs["moveRef"].dialog = true;
      if (!this.folderData.length) {
        this.$refs["moveRef"].loading = true;
        let data = new FormData();
        EduNetdiskListDG(data)
          .then((res) => {
            this.$refs["moveRef"].loading = false;
            this.folderData = res.data;
            this.tagTypeQuery(this.sortBtnName);
          })
          .catch(() => {
            this.$refs["moveRef"].loading = false;
          });
      }
    },
    initQuery() {
      this.tagTypeQuery(this.sortBtnName);
    },
  },
};
</script>
