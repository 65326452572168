<template>
  <el-dialog
    title="新建文件夹"
    :visible.sync="dialog"
    width="30%"
    custorm-class="teacher_cloud_new_folder"
  >
    <el-form :model="form" label-width="100px" size="medium">
      <el-form-item label="文件夹名称" required>
        <el-input v-model="form.fileName"></el-input>
      </el-form-item>
      <el-form-item
        label="支持逗号分隔批量添加"
        label-width="160px"
      ></el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        icon="el-icon-circle-close"
        type="primary"
        @click="save"
        :loading="loading"
      >
        保存并关闭
      </el-button>
      <el-button
        icon="el-icon-folder-add"
        type="primary"
        @click="saveCreate"
        :loading="loading"
      >
        保存后继续创建
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { EduNetdiskAddFile } from "@/libs/api/teacher/cloud";
export default {
  name: "teacher_cloud_new_folder",
  props: ["PFileId"],
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        fileName: null,
      },
    };
  },
  methods: {
    save() {
      if (!this.form.fileName) return this.$message.error("文件夹名称不能为空");
      this.confirm(true);
    },
    saveCreate() {
      if (!this.form.fileName) return this.$message.error("文件夹名称不能为空");
      this.confirm();
    },
    confirm(close) {
      this.loading = true;
      EduNetdiskAddFile({
        fileName: this.form.fileName,
        PFileId: this.PFileId,
      })
        .then((res) => {
          this.loading = false;
          if (close) this.dialog = false;
          this.$emit("initQuery");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.teacher_cloud_new_folder {
}
</style>