
<style scoped lang="less">
.Cloud_ViewModel {
  .items {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border: 2px solid #ececec;
    // box-sizing: border-box;
    border-radius: 8px;
    width: 160px;
    height: 180px;
    padding: 10px 0 0px;
    &:hover {
      position: relative;
      .bottom {
        display: inline-block;
        position: absolute;
        height: 35px;
        line-height: 35px;
        bottom: 0;
      }
    }
    .el-checkbox {
      margin-left: 10px;
    }
    .folder {
      cursor: pointer;
      margin-top: 10px;
      width: 100%;
      height: 110px;
    }
    .fileUrl {
      cursor: pointer;
      margin: 10px 0;
      width: 160px;
      height: 110px;
      background: #f5f7fa;
      border-radius: 4px;
    }
    .image-none {
      border-radius: 4px;
      width: 110px;
      height: 80px;
      margin: 15px auto;
      display: block;
    }
    .bottom {
      display: none;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      .el-col {
        cursor: pointer;
      }
    }
  }
  .pdb40 {
    &:hover {
      padding: 10px 0 40px;
    }
  }
  .fileName {
    // position: absolute;
    text-align: center;
    box-sizing: border-box;
    width: 180px;
    height: 30px;
    line-height: 30px;
    margin: 8px 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<template>
  <div class="Cloud_ViewModel">
    <div>
      <div class="title">
        我的网盘：
        <el-button type="primary" @click="selectAll">全选</el-button>
      </div>
      <el-button class="rootBtn" type="text" @click="handleViewRootNav">
        根目录
      </el-button>
      <template v-for="(c, i) in childData">
        <el-button
          style="marginl-left: 10px"
          :key="i"
          class="rootBtn"
          type="text"
          @click="rootNav(c, i)"
        >
          > {{ c.fileName }}
        </el-button>
      </template>
    </div>

    <el-row :gutter="30">
      <el-col
        v-for="(item, index) in children || data"
        :key="index"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="4"
      >
        <div class="items">
          <el-checkbox
            v-model="item.check"
            @change="handleChange(item)"
          ></el-checkbox>
          <div
            v-if="item.fileType === 0"
            class="folder"
            @click="nodeClick(item)"
          >
            <svg-icon name="folder"></svg-icon>
          </div>
          <el-image v-else :src="item.fileUrl" class="fileUrl">
            <div slot="error" class="image-none">
              <img class="image-none" src="../../../../assets/logo_top.png" />
            </div>
          </el-image>
          <el-row class="bottom" v-if="item.fileType !== 0">
            <el-col :span="12" @click.native="handleDownload(item)"
              >下载</el-col
            >
            <el-col :span="12" @click.native="handleView(item)">预览</el-col>
          </el-row>
        </div>
        <el-tooltip
          class="fileName"
          effect="dark"
          :content="item.fileName"
          placement="top"
        >
          <span>{{ item.fileName }}</span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-pagination
      class="pagination"
      background
      v-if="!children"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="dialog"
      width="40%"
      title="预览"
      @close="closeAudio()"
    >
      正在播放.......
    </el-dialog>
    <el-dialog
      :visible.sync="dialog2"
      width="40%"
      title="预览"
      @close="closeAudio2()"
    >
      <div>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imageDialog" width="40%" title="预览">
      <el-image :src="imagesUrl" style="width: 100%; height: 100%"></el-image>
    </el-dialog>
  </div>
</template>

<script>
const audio = new Audio();
import { EduNetdiskDownloadById } from "@/libs/api/teacher/cloud";
export default {
  name: "cloud_viewModel",
  props: {
    data: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      resHtml: "",
      dialog4: false,
      imagesUrl: "",
      imageDialog: false,
      dialog2: false,
      dialog: false,
      children: null,
      c_page: 1,
      c_pageSize: 12,
      childData: [],
      selectionSelect: [],
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: "",
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    };
  },
  methods: {
    handleView(row) {
      // 图片
      if (row.fileType == 1) {
        this.imageDialog = true;
        this.imagesUrl = row.fileUrl;
        // window.open(
        //   "https://view.officeapps.live.com/op/view.aspx?src=" + row.fileUrl
        // );
      }
      //音频
      if (row.fileType == 2) {
        this.dialog = true;
        audio.src = row.fileUrl;
        let playPromise;
        playPromise = audio.play();
      }
      //视频
      if (row.fileType == 3) {
        this.dialog2 = true;
        this.playerOptions.sources[0].src = row.fileUrl;
      }
    },
    handleDownload(row) {
      if (row) {
        EduNetdiskDownloadById({ id: row.id })
          .then((res) => {
            let blob = new Blob([res], { type: "image/jpeg" });
            let el = document.createElement("a");
            let href = window.URL.createObjectURL(blob);
            el.href = href;
            el.download = row.fileName;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            window.URL.revokeObjectURL(href);
          })
          .catch(() => {
            this.$message.error("无文件或已损坏");
          });
      }
    },
    checkedWith(bool) {
      let arr = [];
      let data = this.children || this.data;
      data.forEach((item) => {
        this.$set(item, "check", bool);
        if (item.check) arr.push(item);
      });
      this.selectionSelect = arr;
      this.$emit("handleSelection", arr);
    },
    // 全选
    selectAll() {
      this.checkedWith(true);
    },
    handleChange(row) {
      if (row) {
        const list = this.selectionSelect.map((m) => m.id);
        if (!list.includes(row.id)) {
          this.selectionSelect.push(row);
        } else {
          const index = list.findIndex((f) => f === row.id);
          this.selectionSelect.splice(index, 1);
        }
        this.$emit("handleSelection", this.selectionSelect);
      }
    },
    nodeClick(row) {
      if (row.fileType === 0) {
        this.children = row.eduNetdiskVos;
        if (this.children) {
          this.$emit("pfileId", row.id);
          this.childData.push({
            fileName: row.fileName,
            pfileId: row.id,
            data: row.eduNetdiskVos,
          });
          this.checkedWith(false);
        }
      }
    },
    // view根目录
    handleViewRootNav() {
      this.children = null;
      this.$emit("pfileId", null);
      this.childData = [];
      this.checkedWith(false);
    },
    // 子节点
    rootNav(item, index) {
      if (item) {
        this.children = item.data;
        this.$emit("pfileId", item.id ? item.id : item.pfileId);
        this.childData.splice(index + 1, this.childData.length - index);
        this.checkedWith(false);
      }
    },
    closeAudio2() {
      this.$refs["videoPlayer"].player.pause();
    },
    closeAudio() {
      audio.pause();
    },
    handleCurrentChange(page) {
      if (page) {
        if (this.children) {
          this.c_page = page;
        } else {
          this.$emit("pageInit", page);
        }
      }
    },
  },
};
</script>
