import request from '../../request'
import qs from 'qs'

// 我的网盘 - 查询
const EduNetdiskList = (data) => {
    return request.post(`eduNetdisk/list`, data).then(res => res.data);
}
// 我的网盘-移动
const EduNetdiskMove = (data) => {
    return request.post(`eduNetdisk/move`, data).then(res => res.data);
}
// 我的网盘-删除
const EduNetdiskDelete = (data) => {
    return request.post(`eduNetdisk/delete`, data).then(res => res.data);
}
// 我的网盘-排序
const EduNetdiskSort = (data) => {
    return request.post(`eduNetdisk/sort`, data).then(res => res.data);
}
// 我的网盘-新建文件夹
const EduNetdiskAddFile = (p) => {
    return request.get(`eduNetdisk/addFile?${qs.stringify(p)}`).then(res => res.data);
}
// 我的网盘-下载
const EduNetdiskDownloadById = (params) => {
    return request({
        method: 'get',
        url: `eduNetdisk/downloadById`,
        params,
        responseType: 'blob'
    }).then(res => res.data);
}
// 我的网盘 - 上传文件
const ExemptionSubmit = (data) => {
    return request.post(`eduNetdisk/exemptionSubmit`, data).then(res => res.data);
}
// 我的网盘 - 文件夹递归查询
const EduNetdiskListDG = (data) => {
    return request.post(`eduNetdisk/listDG`, data).then(res => res.data);
}
// 我的回收站 - 查询
const EduNetdiskDelList = (data) => {
    return request.post(`eduNetdisk/delList`, data).then(res => res.data);
}
// 我的回收站 - 彻底删除
const EduNetdiskDeleteCompletely = (data) => {
    return request.post(`eduNetdisk/DeleteCompletely`, data).then(res => res.data);
}
// 我的回收站 - 还原文件
const EduNetdiskRecovery = (data) => {
    return request.post(`eduNetdisk/recovery`, data).then(res => res.data);
}
export {
    EduNetdiskList,
    EduNetdiskMove,
    EduNetdiskDelete,
    EduNetdiskSort,
    EduNetdiskAddFile,
    EduNetdiskDownloadById,
    ExemptionSubmit,
    EduNetdiskListDG,
    EduNetdiskDelList,
    EduNetdiskDeleteCompletely,
    EduNetdiskRecovery
}