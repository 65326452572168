<style scoped lang="less">
.Cloud_ListModel {
  .folder {
    cursor: pointer;
    width: 100%;
    height: 90px;
  }
  .fileUrl {
    width: 110px;
    height: 80px;
    background: #f5f7fa;
    border-radius: 4px;
  }

  .image-none {
    border-radius: 4px;
    width: 100px;
    height: 70px;
    margin: 6px auto;
    display: block;
  }
}
</style>
<template>
  <div class="Cloud_ListModel">
    <div>
      <div class="title">我的网盘：</div>
      <el-button class="rootBtn" type="text" @click="handleListRootNav">
        根目录
      </el-button>
      <template v-for="(c, i) in childData">
        <el-button
          style="marginl-left: 10px"
          :key="i"
          class="rootBtn"
          type="text"
          @click="rootNav(c, i)"
        >
          > {{ c.fileName }}
        </el-button>
      </template>
    </div>

    <el-table
      :data="children || data"
      :height="450"
      @selection-change="selectionChange"
      @row-click="rowClick"
    >
      <el-table-column
        type="selection"
        align="center"
        width="60"
      ></el-table-column>
      <el-table-column label="序号" align="center" width="70">
        <template slot-scope="scope">
          <span v-if="children">{{
            scope.$index + (c_page - 1) * c_pageSize + 1
          }}</span>
          <span v-else>{{ scope.$index + (page - 1) * pageSize + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="160">
        <template slot-scope="scope">
          <!-- 文件夹 -->
          <div v-if="scope.row.fileType === 0" class="folder">
            <svg-icon name="folder"></svg-icon>
          </div>
          <el-image
            v-else-if="scope.row.fileUrl || scope.row.fileUrl === null"
            :src="scope.row.fileUrl"
            class="fileUrl"
          >
            <div slot="error" class="image-none">
              <img class="image-none" src="../../../../assets/logo_top.png" />
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        :min-width="item.width"
        :show-overflow-tooltip="item.tooltip"
        align="center"
      ></el-table-column>
      <el-table-column align="center" width="160" prop="fileType" label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.fileType == 0">文件夹</span>
          <span v-else-if="scope.row.fileType == 1">文件</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        prop="fileSize"
        label="文件大小"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.fileSize">{{
            scope.row.fileSize | numFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.fileType !== 0">
            <el-button type="text" @click="handleDownLoad(scope.row)"
              >下载
            </el-button>
            <el-button type="text" @click="handleView(scope.row)"
              >预览</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!children"
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- <el-pagination
      v-if="!children"
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="children ? c_page : page"
      :page-size="children ? c_pageSize : pageSize"
      layout="total, prev, pager, next, jumper"
      :total="children ? children.length : total"
    >
    </el-pagination> -->

    <el-dialog
      :visible.sync="dialog"
      width="40%"
      title="预览"
      @close="closeAudio()"
    >
      正在播放.......
    </el-dialog>
    <el-dialog
      :visible.sync="dialog2"
      width="40%"
      title="预览"
      @close="closeAudio2()"
    >
      <div>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imageDialog" width="40%" title="预览">
      <el-image :src="imagesUrl" style="width: 100%; height: 100%"></el-image>
    </el-dialog>
    <el-dialog :visible.sync="dialog4" width="40%" title="预览">
      <div style="height: 600px">
        <div style="height: 500px">
          <div
            style="
              width: 100%;
              height: 30px;
              background: #f3f2f1;
              position: relative;
              top: 92px;
              left: 2px;
            "
          ></div>
          <iframe
            id="iframe"
            scrolling="auto"
            style="width: 100%; height: 100%"
            :src="fileUrl"
          >
          </iframe>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const audio = new Audio();
import { EduNetdiskDownloadById } from "@/libs/api/teacher/cloud";
export default {
  name: "cloud_listModel",
  props: {
    data: Array,
    columns: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      fileUrl: null,
      resHtml: "",
      dialog4: false,
      imagesUrl: "",
      imageDialog: false,
      dialog2: false,
      dialog: false,
      selectionData: [],
      children: null,
      childData: [], // 存放点击过的子集合
      c_page: 1,
      c_pageSize: 12,
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: "",
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    };
  },
  filters: {
    numFilter(value) {
      let realVal = (value / 1024).toFixed(2);
      if (realVal > 1024) {
        return (realVal / 1024).toFixed(2) + "M";
      }
      return realVal + "KB";
    },
  },
  methods: {
    handleRootNav() {},
    selectionChange(val) {
      this.selectionData = val;
      this.$emit("handleSelection", val);
    },
    handleCurrentChange(page) {
      if (page) {
        if (this.children) {
          this.c_page = page;
        } else {
          this.children = null;
          this.$emit("pageInit", page);
        }
      }
    },
    // 点击行
    rowClick(row) {
      if (row.fileType === 0) {
        this.children = row.eduNetdiskVos;
        if (this.children) {
          this.$emit("pfileId", row.id);
          this.childData.push({
            fileName: row.fileName,
            pfileId: row.id,
            data: row.eduNetdiskVos,
          });
        }
      }
    },
    // 根目录
    handleListRootNav() {
      this.children = null;
      this.$emit("pfileId", 0);
      this.childData = [];
    },
    // 子节点
    rootNav(item, index) {
      if (item) {
        this.children = item.data;
        this.$emit("pfileId", item.id ? item.id : item.pfileId);
        this.childData.splice(index + 1, this.childData.length - index);
      }
    },
    handleDownLoad(row) {
      if (row) {
        EduNetdiskDownloadById({ id: row.id })
          .then((res) => {
            // , { type: "image/jpeg" }
            const blob = new Blob([res], { type: res.type });
            const url = window.URL.createObjectURL(res);
            let el = document.createElement("a");
            el.href = url;
            el.download = "";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            const { message } = err;
            this.$message.error("无文件或已损坏");
          });
      }
    },
    handleView(row) {

      let fileType = null;
      let fileUrl = row.fileUrl;
      let fileUrlArray = fileUrl.split(".");
      let suffix = fileUrlArray[fileUrlArray.length - 1];
      let imgList = [
        "png",
        "PNG",
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "bmp",
        "BMP",
        "gif",
        "GIF",
      ];
      let result = imgList.some(function (item) {
        return item === suffix;
      });
      if (result) {
        fileType = 1;
      } else if (suffix === "mp3" || suffix === "MP3") {
        fileType = 2;
      } else if (suffix === "mp4" || suffix === "MP4") {
        fileType = 3;
      } else if (
        suffix === "doc" ||
        suffix === "DOC" ||
        suffix === "docx" ||
        suffix === "DOCX"
      ) {
        fileType = 5;
        this.fileUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" + row.fileUrl;
      } else if (suffix === "pdf" || suffix === "PDF") {
        fileType = 5;
      } else {
        fileType = 6;
      }
      // 图片
      if (fileType === 1) {
        this.imageDialog = true;
        this.imagesUrl = row.fileUrl;
        // window.open(
        //   "https://view.officeapps.live.com/op/view.aspx?src=" + row.fileUrl
        // );
      }
      //音频
      if (fileType === 2) {
        this.dialog = true;
        audio.src = row.fileUrl;
        let playPromise;
        playPromise = audio.play();
      }
      //视频
      if (fileType === 3) {
        this.dialog2 = true;
        this.playerOptions.sources[0].src = row.fileUrl;
      }

      if (fileType === 5) {
        this.dialog4 = true;
        this.resHtml = row.fileUrl;
      }

      if (fileType === 6) {
        this.$message.warning("无法预览");
      }
    },
    closeAudio2() {
      this.$refs["videoPlayer"].player.pause();
    },
    closeAudio() {
      audio.pause();
    },
  },
};
</script>
