<template>
  <el-dialog
    title="移动到所选位置"
    :visible.sync="dialog"
    width="30%"
    custorm-class="teacher_cloud_move"
  >
    <el-tree
      v-loading="loading"
      style="max-height: 350px; overflow-y: auto"
      :data="data"
      :props="defaultProps"
      default-expand-all
      highlight-current
      node-key="id"
      ref="tree"
      @node-click="nodeClick"
    >
    </el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialog = false">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { EduNetdiskMove } from "@/libs/api/teacher/cloud";
export default {
  name: "teacher_cloud_move",
  props: ["data", "ids"],
  data() {
    return {
      dialog: false,
      loading: false,
      defaultProps: {
        children: "eduNetdiskVos",
        label: "fileName",
      },
      nodeData: null,
    };
  },
  methods: {
    nodeClick(data) {
      this.nodeData = data;
    },
    confirm() {
      if (!this.ids) {
        return this.$message.error("请选择列表中的数据");
      }
      if (!this.nodeData) {
        return this.$message.error("请选择要移动的节点");
      }
      let data = new FormData();
      data.append("ids", this.ids);
      data.append("PFileId", this.nodeData.id);
      EduNetdiskMove(data).then((res) => {
        this.$message.success("操作成功");
        this.$emit("initQuery");
      });
    },
  },
};
</script>

<style scoped  lang="less">
.teacher_cloud_move {
}
</style>